import React, { createContext, useReducer } from "react";

import {
    FilterState,
    OpportunityFilterType,
} from "Common/Models/Opportunity/opportunity.model";
import {
    FilterTypeEnum,
    RCChatStatusEnum,
    TimeIntervalEnum,
} from "Common/Enums/contact.enum";
import { UserPreference } from "Common/Models/authresponse.model";
import MXCache from "Common/CacheManager/MXCache";
import { AppTypeEnum, CurrentAppModel } from "./current-app.context";
import RecentContactService from "Services/APIServices/recentcontact.service";

const CurrentFilterContext = createContext<FilterContext>({} as FilterContext);
const { Provider } = CurrentFilterContext;

export interface DispatchAction {
    type: string;
    payload: FilterState;
    eventCode?: string;
    decreaseBy?: number;
    carterUnreadCount?: number;
    timeInterval?: TimeIntervalEnum;
    waitTime?: number;
    startDate?: string;
    endDate?: string;
    rcChatStatus?: number;
    selectedUserIds?: string[];
    selectedUserNames?: string[];
    currentApp?: CurrentAppModel;
}

const initialFilterState: FilterState = {
    filterType: FilterTypeEnum.All,
    eventDetails: [],
    totalUnreadCount: 0,
    totalUnansweredChatCount: 0,
    timeInterval: TimeIntervalEnum.None,
    customDateInterval: { startDate: "0", endDate: "0" },
    applyFilter: 0,
    applyFilterCount: 0,
    opportunityEventCode: [], // this is selected opp event codes
    myInbox: {
        allChatCount: 0,
        myChatCount: 0,
        unansweredChatCount: 0,
        unreadChatCount: 0,
    },
    teamInbox: {
        teamAllInbox: 0,
        teamUnansweredInbox: 0,
        teamUnassignedInbox: 0,
    },

    isInboxCountLoaded: false,
    selectedUserIds: [],
    selectedUserNames: [],
    waitTime: 0,
    rcChatStatus: RCChatStatusEnum.None,
    AgentList: [],
    selectedAgentIds: [],
    businessNumberList: [],
    selectedBusinessNumbers: []
};

const setUserPrefInCache = (
    newState: FilterState,
    filterType: FilterTypeEnum,
    currentApp?: CurrentAppModel
) => {
    if (filterType < 4) {
        MXCache.SetCacheItem(MXCache.UserPreference, newState, 600);

    }
};

const reducer = (state: FilterState, action: DispatchAction) => {
    let newState = {} as FilterState;
    let prevState = state;
    switch (action.type) {
        case FilterContextActionType.SET_FILTER_STATE:
            newState = { ...state };

            newState.filterType = action.payload.filterType;
            newState.widgetFilterType = action.payload.widgetFilterType;
            newState.fullScreenFilterType = action.payload.fullScreenFilterType;
            newState.eventDetails = [
                ...action.payload.eventDetails.map((oppType) => {
                    return {
                        ...oppType,
                        isSelected:
                            action.payload.opportunityEventCode.includes(
                                oppType.eventCode
                            ),
                    };
                }),
            ];
            newState.AgentList = [
                ...action.payload.AgentList.map((agent) => {
                    return {
                        ...agent,
                        isSelected: action.payload.selectedAgentIds.includes(
                            agent.userId
                        ),
                    };
                }),
            ];
            newState.selectedAgentIds = action.payload.selectedAgentIds;
            newState.opportunityEventCode = action.payload.opportunityEventCode;
            newState.totalUnreadCount =
                Number(action.payload.totalUnreadCount) < 0
                    ? 0
                    : Number(action.payload.totalUnreadCount);
            newState.totalUnansweredChatCount = Number(
                action.payload.totalUnansweredChatCount
            );
            newState.myInbox = action.payload.myInbox;
            newState.teamInbox = action.payload.teamInbox;
            newState.isInboxCountLoaded = action.payload.isInboxCountLoaded;
            newState.businessNumberList = [
                ...action.payload.businessNumberList.map((bn) => {
                    return {
                        ...bn,
                        isSelected: action.payload.selectedBusinessNumbers.includes(
                            bn.BusinessPhoneNumber
                        ),
                    };
                }),
            ];
            newState.selectedBusinessNumbers = action.payload.selectedBusinessNumbers;
            setUserPrefInCache(
                newState,
                newState.filterType,
                action.currentApp
            );
            return newState;

        case FilterContextActionType.SET_FILTER_USER_PREFERENCE: {
            newState = { ...state };

            newState.filterType = action.payload.filterType;
            newState.widgetFilterType = action.payload.widgetFilterType;
            newState.fullScreenFilterType = action.payload.fullScreenFilterType;
            newState.opportunityEventCode = [
                ...action.payload.opportunityEventCode,
            ];
            newState.timeInterval = action.payload.timeInterval;
            newState.customDateInterval = action.payload.customDateInterval;
            newState.waitTime = action.payload.waitTime;
            newState.rcChatStatus = action.payload.rcChatStatus;
            newState.selectedUserIds = action.payload.selectedUserIds;
            newState.selectedUserNames = action.payload.selectedUserNames;
            newState.AgentList = action.payload.AgentList;
            newState.selectedAgentIds = action.payload.selectedAgentIds;
            newState.selectedBusinessNumbers = action.payload.selectedBusinessNumbers;
            setUserPrefInCache(newState, prevState.filterType);
            return newState;
        }
        case FilterContextActionType.SET_FILTER_TYPE:
            newState = { ...state };
            newState.filterType = action.payload.filterType;
            newState.widgetFilterType =
                action.payload.currentAppType !== AppTypeEnum.Fullscreen &&
                    action.payload.filterType !== FilterTypeEnum.None
                    ? action.payload.filterType
                    : newState.widgetFilterType;
            newState.fullScreenFilterType =
                action.payload.currentAppType === AppTypeEnum.Fullscreen &&
                    action.payload.filterType !== FilterTypeEnum.None
                    ? action.payload.filterType
                    : newState.fullScreenFilterType;
            // setUserPrefInCache(newState, action.payload.filterType);
            return newState;
        case FilterContextActionType.SET_OPPORTUNITY_TYPES:
            newState = { ...state };
            newState.eventDetails = [...action.payload.eventDetails];
            return newState;
        case FilterContextActionType.INCREMENT_APPLY:
            newState = { ...state };
            newState.applyFilter = state.applyFilter + 1;
            return newState;

        case FilterContextActionType.INCREMENT_APPLY_FILTER_COUNT:
            newState = { ...state };
            newState.applyFilterCount = state.applyFilterCount + 1;
            return newState;

        case FilterContextActionType.INCREMENT_UNREAD_COUNT:
            return incrementOppUnreadCount(state, action);
        case FilterContextActionType.INCREMENT_UNREAD_CHAT_COUNT:
            return incrementOppUnreadChatCount(state, action);
        case FilterContextActionType.INCREMENT_UNANSWERED_CHAT_COUNT:
            return incrementOppUnansweredChatCount(state, action);
        case FilterContextActionType.DECREMENT_UNANSWERED_CHAT_COUNT:
            return decrementOppUnansweredChatCount(state, action);
        case FilterContextActionType.DECREMENT_UNREAD_COUNT:
            return decrementOppUnreadCount(state, action);
        case FilterContextActionType.DECREMENT_UNREAD_CHAT_COUNT:
            return decrementOppUnreadChatCount(state, action);
        case FilterContextActionType.CHANGE_TIME_INTERVAL:
            newState = { ...state };
            newState.timeInterval = action.timeInterval
                ? action.timeInterval
                : TimeIntervalEnum.None;
            setUserPrefInCache(newState, newState.filterType);
            return newState;
        case FilterContextActionType.CUSTOM_DATE_INTERVAL:
            newState = { ...state };
            newState.customDateInterval = {
                startDate: action.startDate,
                endDate: action.endDate,
            };
            setUserPrefInCache(newState, newState.filterType);
            return newState;

        case FilterContextActionType.CHANGE_WAIT_TIME:
            newState = { ...state };
            newState.waitTime = action.waitTime ? action.waitTime : 0;
            setUserPrefInCache(newState, newState.filterType);
            return newState;
        case FilterContextActionType.CHANGE_RC_CHAT_STATUS:
            newState = { ...state };
            newState.rcChatStatus = action.rcChatStatus
                ? action.rcChatStatus
                : RCChatStatusEnum.None;
            setUserPrefInCache(newState, newState.filterType);
            return newState;
        case FilterContextActionType.CHANGE_SELECTED_USER_IDS:
            newState = { ...state };
            newState.selectedUserIds = action.selectedUserIds
                ? action.selectedUserIds
                : [];
            newState.selectedUserNames = action.selectedUserNames
                ? action.selectedUserNames
                : [];
            setUserPrefInCache(newState, newState.filterType);

            return newState;

        case FilterContextActionType.REMOVE_DASHBOARD_SELECTED_FILTERS:
            newState = { ...state };
            newState.selectedUserIds = initialFilterState.selectedUserIds;
            newState.selectedUserNames = initialFilterState.selectedUserNames;
            newState.rcChatStatus = initialFilterState.rcChatStatus;
            newState.timeInterval = initialFilterState.timeInterval;
            newState.customDateInterval = initialFilterState.customDateInterval;
            newState.waitTime = initialFilterState.waitTime;
            // setUserPrefInCache(newState, newState.filterType);
            return newState;
        default:
            return state;
    }
};

const incrementOppUnreadCount = (
    state: FilterState,
    action: DispatchAction
) => {
    const finalSum =
        state.myInbox.unreadChatCount + (action.carterUnreadCount ?? 0) + 1;

    const newState = {
        ...state,
        totalUnreadCount: finalSum,
        myInbox: {
            ...state.myInbox,
            unreadChatCount: finalSum,
        },
    };
    setUserPrefInCache(newState, newState.filterType);
    return newState;
};

const incrementOppUnreadChatCount = (
    state: FilterState,
    action: DispatchAction
) => {
    const finalSum = state.totalUnreadCount + 1;

    const newState = {
        ...state,
        totalUnreadCount: finalSum,
        myInbox: {
            ...state.myInbox,
            unreadChatCount: finalSum,
        },
    };

    setUserPrefInCache(newState, newState.filterType);
    MXCache.SetCacheItem(
        MXCache.UnreadChatCount,
        {
            totalUnreadChatCount: finalSum,
        },
        480
    );
    if (state.currentAppType !== AppTypeEnum.Fullscreen) {
        MXCache.SetCacheItem(
            MXCache.opportunityFilterOptions,
            {
                eventDetails: state.eventDetails,
                myInbox: newState.myInbox,
            },
            600
        );
    }
    return newState;
};

const incrementOppUnansweredChatCount = (
    state: FilterState,
    action: DispatchAction
) => {
    const finalSum = state.totalUnansweredChatCount + 1;
    const newState = {
        ...state,
        totalUnansweredChatCount: finalSum,
        myInbox: {
            ...state.myInbox,
            unansweredChatCount: finalSum,
        },
    };
    setUserPrefInCache(newState, newState.filterType);
    return newState;
};

const decrementOppUnreadCount = (
    state: FilterState,
    action: DispatchAction
) => {
    const finalSum =
        state.myInbox.unreadChatCount +
        (action.eventCode === "100_C" ? 0 : action.carterUnreadCount ?? 0) -
        1;
    const newState = {
        ...state,
        totalUnreadCount: finalSum < 0 ? 0 : finalSum,
        myInbox: {
            ...state.myInbox,
            unreadChatCount: finalSum < 0 ? 0 : finalSum,
        },
    };
    setUserPrefInCache(newState, newState.filterType);
    return newState;
};

const decrementOppUnreadChatCount = (
    state: FilterState,
    action: DispatchAction
) => {
    const finalSum = state.totalUnreadCount - 1;

    const newState = {
        ...state,
        totalUnreadCount: finalSum < 0 ? 0 : finalSum,
        myInbox: {
            ...state.myInbox,
            unreadChatCount: finalSum < 0 ? 0 : finalSum,
        },
    };
    setUserPrefInCache(newState, newState.filterType);
    MXCache.SetCacheItem(
        MXCache.UnreadChatCount,
        {
            totalUnreadChatCount: finalSum,
        },
        240
    );
    if (state.currentAppType !== AppTypeEnum.Fullscreen) {
        MXCache.SetCacheItem(
            MXCache.opportunityFilterOptions,
            {
                eventDetails: state.eventDetails,
                myInbox: newState.myInbox,
            },
            600
        );
    }
    return newState;
};

const decrementOppUnansweredChatCount = (
    state: FilterState,
    action: DispatchAction
) => {
    const finalSum = state.totalUnansweredChatCount - 1;
    const newState = {
        ...state,
        totalUnansweredChatCount: finalSum,
        myInbox: { ...state.myInbox, unansweredChatCount: finalSum },
    };
    setUserPrefInCache(newState, newState.filterType);
    return newState;
};

// const setCarterPresent = (state: FilterState, action: DispatchAction) => {
//     const newState = {
//         ...state,
//         isCarterPresent: action.payload.isCarterPresent,
//         isCarterCountAdded: action.payload.isCarterCountAdded,
//     };
//     setUserPrefInCache(newState, newState.filterType);
//     return newState;
// };

const FilterProvider = ({ children }: any) => {
    const [state, dispatch] = useReducer(
        reducer,
        initialFilterState as FilterState
    );
    return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

interface FilterContext {
    state: FilterState;
    dispatch: React.Dispatch<DispatchAction>;
}

class FilterContextActionType {
    static SET_FILTER_STATE = "SET_FILTER_STATE";
    static SET_FILTER_TYPE = "SET_FILTER_TYPE";
    static SET_FILTER_USER_PREFERENCE = "SET_FILTER_USER_PREFERENCE";
    static SET_OPPORTUNITY_TYPES = "SET_OPPORTUNITY_TYPES";
    static INCREMENT_APPLY = "INCREMENT_APPLY";
    static INCREMENT_APPLY_FILTER_COUNT = "INCREMENT_APPLY_FILTER_COUNT";
    static INCREMENT_UNREAD_COUNT = "INCREMENT_UNREAD_COUNT";
    static INCREMENT_UNREAD_CHAT_COUNT = "INCREMENT_UNREAD_CHAT_COUNT";
    static DECREMENT_UNREAD_COUNT = "DECREMENT_UNREAD_COUNT";
    static DECREMENT_UNREAD_CHAT_COUNT = "DECREMENT_UNREAD_CHAT_COUNT";
    static CHANGE_TIME_INTERVAL = "CHANGE_TIME_INTERVAL";
    static CUSTOM_DATE_INTERVAL = "CUSTOM_DATE_INTERVAL";
    static CHANGE_WAIT_TIME = "CHANGE_WAIT_TIME";
    static INCREMENT_UNANSWERED_CHAT_COUNT = "INCREMENT_UNANSWERED_CHAT_COUNT";
    static DECREMENT_UNANSWERED_CHAT_COUNT = "DECREMENT_UNANSWERED_CHAT_COUNT";
    static SET_CARTER_PRESENT = "SET_CARTER_PRESENT";
    static CHANGE_RC_CHAT_STATUS = "CHANGE_RC_CHAT_STATUS";
    static CHANGE_SELECTED_USER_IDS = "CHANGE_SELECTED_USER_IDS";
    static REMOVE_DASHBOARD_SELECTED_FILTERS =
        "REMOVE_DASHBOARD_SELECTED_FILTERS";
}

class FilterContextActions {
    static setFilters = (data: FilterState, currentApp?: CurrentAppModel) => ({
        type: "SET_FILTER_STATE",
        payload: {
            ...data,
        },
        currentApp,
    });

    static setCarterPresent = (data: FilterState) => ({
        type: "SET_CARTER_PRESENT",
        payload: {
            ...data,
        },
    });
    static setUserPreferenceFilters = (data: FilterState) => ({
        type: "SET_FILTER_USER_PREFERENCE",
        payload: {
            ...data,
        },
    });

    static setFilterType = (
        data: FilterTypeEnum,
        currentAppType: AppTypeEnum
    ) => ({
        type: "SET_FILTER_TYPE",
        payload: {
            filterType: data,
            currentAppType: currentAppType,
        } as FilterState,
    });

    static setOpportunityTypes = (data: OpportunityFilterType[]) => ({
        type: "SET_OPPORTUNITY_TYPES",
        payload: {
            eventDetails: data,
        } as FilterState,
    });

    static incrementApply = () => ({
        type: "INCREMENT_APPLY",
        payload: {} as FilterState,
    });

    static incrementApplyFilterCount = () => ({
        type: "INCREMENT_APPLY_FILTER_COUNT",
        payload: {} as FilterState,
    });

    static incrementOppUnreadCount = (
        eventCode: string,
        carterUnreadCount: number
    ) => ({
        type: "INCREMENT_UNREAD_COUNT",
        payload: {} as FilterState,
        eventCode,
        carterUnreadCount,
    });

    static incrementOppUnreadChatCount = (
        eventCode: string,
        carterUnreadCount: number
    ) => ({
        type: "INCREMENT_UNREAD_CHAT_COUNT",
        payload: {} as FilterState,
        eventCode,
        carterUnreadCount,
    });

    static incrementOppUnansweredChatCount = (eventCode: string) => ({
        type: "INCREMENT_UNANSWERED_CHAT_COUNT",
        payload: {} as FilterState,
        eventCode,
    });

    static decrementOppUnansweredChatCount = (eventCode: string) => ({
        type: "DECREMENT_UNANSWERED_CHAT_COUNT",
        payload: {} as FilterState,
        eventCode,
    });

    static decrementOppUnreadCount = (
        eventCode: string,
        decreaseBy: number,
        carterUnreadCount: number
    ) => ({
        type: "DECREMENT_UNREAD_COUNT",
        payload: {} as FilterState,
        eventCode,
        decreaseBy,
        carterUnreadCount,
    });

    static decrementOppUnreadChatCount = (
        eventCode: string,
        carterUnreadCount: number
    ) => ({
        type: "DECREMENT_UNREAD_CHAT_COUNT",
        payload: {} as FilterState,
        eventCode,
        carterUnreadCount,
    });

    static changeTimeInterval = (timeInterval: TimeIntervalEnum) => ({
        type: "CHANGE_TIME_INTERVAL",
        payload: {} as FilterState,
        timeInterval,
    });
    static addCustomeDateInterval = ({
        startDate,
        endDate,
    }: {
        startDate?: string;
        endDate?: string;
    }) => ({
        type: "CUSTOM_DATE_INTERVAL",
        payload: {} as FilterState,
        startDate,
        endDate,
    });

    static changeWaitTime = (waitTime: number) => ({
        type: "CHANGE_WAIT_TIME",
        payload: {} as FilterState,
        waitTime,
    });
    static changeRcChatStatus = (rcChatStatus: number) => ({
        type: "CHANGE_RC_CHAT_STATUS",
        payload: {} as FilterState,
        rcChatStatus,
    });

    static changeSelectedUserIds = ({
        userIds,
        userNames,
    }: {
        userIds: string[];
        userNames: string[];
    }) => ({
        type: "CHANGE_SELECTED_USER_IDS",
        payload: {} as FilterState,
        selectedUserIds: userIds,
        selectedUserNames: userNames,
    });

    static removeDashboardSelectedFilter = () => ({
        type: "REMOVE_DASHBOARD_SELECTED_FILTERS",
        payload: {} as FilterState,
    });
}

export { FilterProvider, CurrentFilterContext, FilterContextActions };
